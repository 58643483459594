import { delay } from '@mockingjay-io/shared-dependencies/src/utils/promise';
import { isBrowser } from './ssr';
import logger from './logger';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Listenable } from './listener';

function buildUrl(url: string, port: number): string {
  const parsedUrl = new URL(url);
  parsedUrl.port = port.toString();
  return parsedUrl.toString();
}

type PersistentWebSocketConnectorConfig = {
  url: string;
  ports: number[];
  retryDelay?: number;
  maxRetries?: number | null; // null means infinite retries
  pingInterval?: number;
};

class PersistentWebSocketConnector extends Listenable<
  'message' | 'response' | `response:${string}`,
  any
> {
  private url: string;
  private ports: number[];
  private retryDelay: number;
  private maxRetries: number | null;
  private pingInterval: number;
  private paused: boolean = false;
  private windowActive: boolean = true;

  @observable connection: WebSocket | null = null;

  @observable currentPort: number = 0;
  @observable currentHostname: string = '';

  private documentVisibilityChangeListener: () => void;

  constructor(config: PersistentWebSocketConnectorConfig) {
    super();
    makeObservable(this);

    this.url = config.url;
    this.ports = config.ports;
    this.retryDelay = config.retryDelay || 1000; // Default to 1 second
    this.maxRetries =
      config.maxRetries !== undefined ? config.maxRetries : null;
    this.pingInterval = config.pingInterval || 5000; // Default to 5 seconds

    this.documentVisibilityChangeListener = () => {
      this.windowActive = !document.hidden;
      if (this.windowActive && !this.connection) {
        this.connect();
      }
    };

    if (!isBrowser()) {
      return;
    }

    window.document.addEventListener(
      'visibilitychange',
      this.documentVisibilityChangeListener,
    );
  }

  public async start(): Promise<void> {
    this.paused = false;
    await this.connect();
  }

  public async stop(): Promise<void> {
    this.paused = true;
    if (this.connection) {
      this.connection.close();
    }
  }

  @action
  public async connect(): Promise<WebSocket | null> {
    let retryCount = 0;
    let portIndex = 0;
    while (
      !this.paused &&
      this.windowActive &&
      (this.maxRetries === null || retryCount < this.maxRetries)
    ) {
      const port = this.ports[portIndex];
      const url = buildUrl(this.url, port);
      try {
        this.connection = await this.connectToWebsocket(url, this.pingInterval);
        this.connection.addEventListener('close', () => {
          logger.info(
            `connection to mockingjay desktop closed. will reconnect after ${this.retryDelay}`,
          );
          runInAction(() => {
            this.connection = null;
          });
          setTimeout(() => this.connect(), this.retryDelay);
        });
        this.currentPort = port;
        this.currentHostname = new URL(this.url).hostname;
        logger.info(`got ws ${this.url}:${port}`);
        return this.connection;
      } catch (e) {
        if (this.maxRetries !== null) {
          retryCount++; // Increment the retry count
        }
        portIndex = (portIndex + 1) % this.ports.length; // Cycle through the ports
        await delay(this.retryDelay);
      }
    }
    logger.info(
      {
        paused: this.paused,
        retryCount,
        portIndex,
        windowActive: this.windowActive,
        maxRetries: this.maxRetries,
      },
      'giving up on connecting to mockingjay desktop',
    );
    return null;
  }

  public dispose() {
    document.removeEventListener(
      'visibilitychange',
      this.documentVisibilityChangeListener,
    );
    if (this.connection) {
      this.connection.close();
    }
  }

  private async connectToWebsocket(
    url: string,
    pingInterval?: number,
  ): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(url);
      ws.addEventListener('open', () => {
        logger.info('connected to mockingjay desktop');
        if (
          pingInterval &&
          ws.readyState !== ws.CLOSING &&
          ws.readyState !== ws.CLOSED
        ) {
          let pingHandle = setInterval(() => {
            if (ws.readyState === ws.CLOSING || ws.readyState === ws.CLOSED) {
              clearInterval(pingHandle);
              return;
            }
            if (ws.readyState === WebSocket.CONNECTING) {
              return;
            }
            if (ws.readyState === ws.OPEN) {
              try {
                ws.send(
                  JSON.stringify({
                    type: 'ping',
                    requestId: uuidv4(),
                  }),
                );
              } catch (e) {
                logger.error('ping failed with desktop', e);
                clearInterval(pingHandle);
                ws.close();
              }
            }
          }, pingInterval);
        }
        resolve(ws);
      });
      ws.addEventListener('error', (e) => {
        reject(e);
      });
      ws.addEventListener('message', (event) => {
        this.emit('message', event.data);
        if (typeof event.data === 'string') {
          try {
            const data = JSON.parse(event.data);
            this.emit('response', data);
            if (data.requestId && data.type !== 'ping') {
              this.emit(`response:${data.requestId}`, data);
            }
          } catch (e) {
            // ignore unparsable messages
          }
        }
      });
    });
  }
}

export default PersistentWebSocketConnector;
