import config from '@/config';
import PersistentWebSocketConnector from '@/utils/persistent-websocket-connector';
import type {
  AssetModules,
  IPCInterface,
  MobileDevice,
  MobileTestingSession,
} from '@mockingjay-io/shared-dependencies/src/types/desktop/ipc';
import axios from 'axios';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

class DesktopService implements IPCInterface {
  @observable public mobileDevices: MobileDevice[] = [];
  controllerPort: number = 0;
  controllerHost: string = '';
  private wsConnector: PersistentWebSocketConnector;

  @computed
  get connection() {
    return this.wsConnector.connection;
  }

  @computed
  get currentPort() {
    return this.wsConnector.currentPort;
  }

  @computed
  get currentHostname() {
    return this.wsConnector.currentHostname;
  }

  ping(): Promise<string> {
    return this.makeCall('ping', []);
  }

  loadAssets(module: AssetModules): Promise<void> {
    return this.makeCall('loadAssets', [module]);
  }

  hasAssets(module: AssetModules): Promise<boolean> {
    return this.makeCall('hasAssets', [module]);
  }

  @action
  async androidGetDevices(): Promise<MobileDevice[]> {
    const devices = await this.makeCall('androidGetDevices', []);
    this.mobileDevices = devices;
    return devices;
  }

  @action
  async androidStartDeviceTracking(): Promise<MobileDevice[]> {
    const devices = await this.makeCall('androidStartDeviceTracking', []);
    this.mobileDevices = devices;
    return devices;
  }

  androidStopDeviceTracking(): Promise<void> {
    return this.makeCall('androidStopDeviceTracking', []);
  }

  mobileStartTestingSession(
    device: MobileDevice,
    appPackageName: string,
  ): Promise<MobileTestingSession> {
    return this.makeCall(
      'mobileStartTestingSession',
      [device, appPackageName],
      60 * 1000,
    );
  }

  mobileStopTestingSession(): Promise<void> {
    return this.makeCall('mobileStopTestingSession', []);
  }

  androidIsPackageInstalled(
    device: MobileDevice | string,
    packageName: string,
  ): Promise<boolean> {
    return this.makeCall('androidIsPackageInstalled', [device, packageName]);
  }

  androidGetInstalledPackages(
    device: MobileDevice | string,
  ): Promise<string[]> {
    return this.makeCall('androidGetInstalledPackages', [device]);
  }

  downloadPackage(device: MobileDevice | string, packageName: string) {
    const deviceSerial = typeof device === 'string' ? device : device.serial;
    return axios.get(
      `http://${this.currentHostname}:${this.currentPort}/api/v1/android/${deviceSerial}/packages/${packageName}/download`,
      {
        responseType: 'blob',
      },
    );
  }

  uploadMediaFile(device: MobileDevice | string, url: string) {
    const deviceSerial = typeof device === 'string' ? device : device.serial;
    return axios.post(
      `http://${this.currentHostname}:${this.currentPort}/api/v1/mobile/${deviceSerial}/upload`,
      {
        url,
      },
    );
  }

  /**
   * Make an RPC call to the desktop.
   *
   * @param type
   * @param args
   * @param timeout
   * @returns
   */
  makeCall<T = any>(
    type: string,
    args: any[],
    timeout = 30 * 1000,
  ): Promise<T> {
    const requestId = uuidv4();
    return new Promise((resolve, reject) => {
      let promiseResolved = false;
      if (!this.connection) {
        reject(new Error('No connection to desktop'));
        return;
      }
      const timeoutHandle = setTimeout(() => {
        if (promiseResolved) {
          return;
        }
        promiseResolved = true;
        reject(
          new Error(
            `Request timed out after ${timeout}ms. ${type} / ${requestId}`,
          ),
        );
      }, timeout);

      const onResponse = (data: any) => {
        if (promiseResolved) {
          return;
        }
        promiseResolved = true;
        clearTimeout(timeoutHandle);
        if (data.error) {
          reject(data.error);
        } else {
          if (data.response) {
            resolve(data.response);
          } else {
            resolve(data);
          }
        }
      };

      this.wsConnector.once(`response:${requestId}`, onResponse);
      try {
        this.connection.send(
          JSON.stringify({
            type,
            args,
            requestId,
          }),
        );
      } catch (e) {
        if (promiseResolved) {
          return;
        }
        promiseResolved = true;
        clearTimeout(timeoutHandle);
        this.wsConnector.off(`response:${requestId}`, onResponse);
        reject(e);
        return;
      }
    });
  }

  start() {
    return this.wsConnector.start();
  }

  stop() {
    return this.wsConnector.stop();
  }

  constructor() {
    makeObservable(this);
    this.wsConnector = new PersistentWebSocketConnector({
      url: config.DESKTOP_CONTROLLER_URL,
      ports: config.DESKTOP_CONTROLLER_PORTS,
    });
    this.wsConnector.on('response', (data) => {
      if (data.type === 'android:devices:change') {
        runInAction(() => {
          this.mobileDevices = data.response;
        });
      }
    });
  }
}

const desktopService = new DesktopService();
export default desktopService;
