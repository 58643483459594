import { isBrowser } from '@/utils/ssr';
import { StepEvent } from '@mockingjay-io/shared-dependencies/src/types/browser/interceptor';
import { LocalVariables } from '@mockingjay-io/shared-dependencies/src/types/entities/test-definition';
import clone from 'just-clone';
import { action, makeObservable, observable } from 'mobx';
import { makePersistable, hydrateStore } from 'mobx-persist-store';

class Clipboard {
  @observable steps: StepEvent[] = [];
  @observable localVariables: LocalVariables = [];

  @action
  reset() {
    this.steps = [];
  }

  getStepsForPasting() {
    return this.steps.map((step) => clone(step));
  }

  constructor() {
    makeObservable(this);
    if (isBrowser()) {
      const storageKey = 'v1:Clipboard';
      makePersistable(this, {
        name: storageKey,
        properties: ['steps', 'localVariables'],
        storage: window.localStorage,
      });
      window.addEventListener('storage', (e) => {
        if (e.key === storageKey && e.storageArea === window.localStorage) {
          hydrateStore(this);
        }
      });
    }
  }
}

const clipboard = new Clipboard();
export default clipboard;
