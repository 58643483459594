import { Container, Text, Button, Group, Stack } from '@mantine/core';
import notFound from '@/static/images/illustrations/undraw_page_not_found.svg';
import Link from 'next/link';
import classes from './404.module.css';
export function NotFoundPage() {
  return (
    <Container fluid className={classes.root}>
      <Stack>
        <img className={classes.image} src={notFound} alt="not found" />
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          Page you are trying to open does not exist. You may have mistyped the
          address, or the page has been moved to another URL. If you think this
          is an error contact support.
        </Text>
        <Group justify="center">
          <Link href="/" passHref={true} legacyBehavior>
            <Button component="a" color="green" variant="outline" size="md">
              Take me back home
            </Button>
          </Link>
        </Group>
      </Stack>
    </Container>
  );
}

export default NotFoundPage;
