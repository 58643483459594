import {
  AppShell,
  Box,
  Paper,
  ScrollArea,
  Stack,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import Header from '../Header/Header';
import SidebarExpanded from '@/components/navigation/Sidebar/SidebarExpanded';
import { useTestViewState } from '@/hooks/tests-view';
import IconButton from '@/components/bricks/IconButton/IconButton';
import ActiveLink from '@/components/bricks/ActiveLink/ActiveLink';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import classes from './Sidebar.module.css';
import {
  topLevelGlobalNavigation,
  topLevelNavigation,
} from '@/data/navigation';
import { useRouter } from 'next/router';
import config from '@/config';

type SidebarProps = {
  navListHidden: boolean;
  errorMode?: boolean;
  width?: number;
  onDynamicWidthChange?: (width: number) => void;
};

function Sidebar({
  navListHidden,
  width = 400,
  errorMode,
  onDynamicWidthChange,
}: SidebarProps) {
  const router = useRouter();
  const theme = useMantineTheme();
  const { isRecorderOpen } = useTestViewState();
  const navbarRef = useRef<HTMLDivElement>(null);
  const expandedNavbarRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback(
    (e: any) => {
      const newWidth = e.clientX - document.body.offsetLeft;
      if (navbarRef.current && newWidth > width && newWidth < width * 2) {
        navbarRef.current.style.width = `${newWidth}px`;
        onDynamicWidthChange?.(newWidth);
        if (expandedNavbarRef.current) {
          expandedNavbarRef.current.style.left = `${newWidth}px`;
        }
      }
    },
    [onDynamicWidthChange, width],
  );

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown: MouseEventHandler = (e) => {
    e.preventDefault();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.style.width = `${width}px`;
      onDynamicWidthChange?.(width);
      if (expandedNavbarRef.current) {
        expandedNavbarRef.current.style.left = `${width}px`;
      }
    }
  }, [onDynamicWidthChange, width]);

  const isGlobalPage = useMemo(
    () =>
      config.authGuard.globalRolePrefixes.some((prefix) =>
        router.route.startsWith(prefix),
      ),
    [router.route],
  );

  return (
    <>
      <AppShell.Navbar
        ref={navbarRef}
        p={0}
        style={{ boxShadow: theme.shadows.xs }}
      >
        <AppShell.Section>
          {!errorMode && isRecorderOpen && <Header isMinimized width={width} />}
          <div id="sidebar-header-portal" />
        </AppShell.Section>
        <AppShell.Section
          grow
          component={ScrollArea}
          className={classes.scrollArea}
        >
          {(errorMode || !isRecorderOpen) && (
            <div
              role="button"
              tabIndex={0}
              onMouseDown={handleMouseDown}
              className={classes.dragger}
            />
          )}

          <div id="sidebar-portal" />
          {!navListHidden ? (
            <Paper p="sm" className={classes.navList}>
              <Stack gap={4}>
                {/*<LinksGroup*/}
                {/*  initiallyOpened*/}
                {/*  icon={PiBrowsersLight}*/}
                {/*  label="Application"*/}
                {/*>*/}
                {(isGlobalPage ? topLevelGlobalNavigation : topLevelNavigation)
                  .filter((item) => !item.enabled || item.enabled())
                  .map(
                    ({
                      teaser,
                      href,
                      Icon,
                      label,
                      enabled,
                      ...activeLinkProps
                    }) => {
                      return teaser ? (
                        <Tooltip
                          key={href}
                          withArrow
                          position="right"
                          label="Coming soon"
                        >
                          <IconButton
                            color="gray"
                            icon={<Icon />}
                            label={label}
                          />
                        </Tooltip>
                      ) : (
                        <ActiveLink key={href} href={href} {...activeLinkProps}>
                          <IconButton icon={<Icon />} label={label} />
                        </ActiveLink>
                      );
                    },
                  )}
              </Stack>
            </Paper>
          ) : null}
        </AppShell.Section>
        <AppShell.Section>
          <Box id="sidebar-actions-portal" />
        </AppShell.Section>
      </AppShell.Navbar>
      <SidebarExpanded ref={expandedNavbarRef} offset={width} />
    </>
  );
}

export default Sidebar;
