/**
 * Digests a message using the given algorithm.
 *
 * @param message
 * @param algorithm
 * @returns
 */
export async function digestMessage(
  message: string | ArrayBuffer | Uint8Array,
  algorithm: AlgorithmIdentifier = 'SHA-256',
): Promise<string> {
  const msgUint8 =
    typeof message === 'string' ? new TextEncoder().encode(message) : message;
  const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}
