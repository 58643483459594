import { BrowserStore } from '@/utils/storage';
import type { IdTokenPayload } from '@mockingjay-io/shared-dependencies/src/types/jwt';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { buildUrlSearchParams } from '@/utils/requests';
import logger from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { isBrowser } from '@/utils/ssr';
import { AuthData, createAxiosInstance } from '@/store/auth';

dayjs.extend(utc);

type OAuthOptions = {
  clientId?: string;
  getRedirectUrl: () => string;
  getStartUrl: () => string;
};

export default class OAuth {
  private options: OAuthOptions;

  constructor(options: OAuthOptions) {
    this.options = options;
  }

  async handleTicketResponse() {
    if (!isBrowser()) {
      return;
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params || !params.ticket) {
      return;
    }
    const oauthState = BrowserStore.getCookie('oauth-state');
    if (params.state !== oauthState) {
      logger.warn('State mis-match');
      return;
    }

    const oauthNonce = BrowserStore.getCookie('oauth-nonce');

    const unauthenticatedAxios = createAxiosInstance();
    const res = await unauthenticatedAxios.post<AuthData>('oauth/claim', {
      ticket: params.ticket,
    });

    const tokenPayload = jwtDecode(res.data.token) as IdTokenPayload;
    if (oauthNonce !== tokenPayload.nonce) {
      logger.warn('Nonce mis-match');
      return;
    }

    return res.data!;
  }

  public async login(provider: string) {
    const state = `oauth-state-${uuidv4()}`;
    const nonce = `oauth-nonce-${uuidv4()}`;
    BrowserStore.setCookie('oauth-state', state, 300);
    BrowserStore.setCookie('oauth-nonce', nonce, 300);
    const urlParams = buildUrlSearchParams({
      state,
      nonce,
      redirect_url: this.options.getRedirectUrl(),
    });
    this.options.clientId &&
      urlParams.append('client_id', this.options.clientId);
    window.location.href = `${this.options.getStartUrl()}/${provider}?${urlParams.toString()}`;
  }
}
