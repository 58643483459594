import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  DefaultMantineColor,
  Group,
  GroupProps,
  Text,
  TextProps,
  ThemeIcon,
  ThemeIconProps,
} from '@mantine/core';
import type { ReactNode } from 'react';
import { forwardRef, MouseEventHandler, useCallback } from 'react';
import classes from './IconButton.module.css';
import cx from 'clsx';

export type IconButtonProps = Omit<BoxProps, 'component'> & {
  icon: ReactNode;
  className?: string;
  href?: string;
  ref?: any;
  color?: DefaultMantineColor;
  label: string;
  badge?: string | number;
  groupProps?: Partial<GroupProps>;
  badgeProps?: Partial<BadgeProps>;
  iconProps?: Partial<ThemeIconProps>;
  labelProps?: Partial<TextProps>;
  onClick?: () => any;
  onIconClick?: () => any;
};

const IconButton = forwardRef(
  (
    {
      icon,
      color,
      label,
      badge,
      className,
      groupProps,
      iconProps,
      badgeProps,
      labelProps,
      onIconClick,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    const onIconClickCallback: MouseEventHandler = useCallback(
      (event) => {
        if (!onIconClick) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        onIconClick();
      },
      [onIconClick],
    );
    return (
      <Box
        component="a"
        ref={ref}
        className={cx(classes.root, className)}
        {...props}
      >
        <Group justify="space-between" gap="sm" wrap="nowrap" {...groupProps}>
          <Group gap="xs" wrap="nowrap">
            <ThemeIcon
              onClick={onIconClickCallback}
              color={color}
              variant="light"
              {...iconProps}
            >
              {icon}
            </ThemeIcon>
            <Text lineClamp={1} size="sm" {...labelProps}>
              {label}
            </Text>
          </Group>
          {typeof badge !== 'undefined' && (
            <Badge size="xs" {...badgeProps}>
              {badge}
            </Badge>
          )}
        </Group>
      </Box>
    );
  },
);

IconButton.displayName = 'IconButton';
export default IconButton;
