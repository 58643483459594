import { observer } from 'mobx-react-lite';
import { AppShell, ScrollArea } from '@mantine/core';
import appShellState from '@/store/app-shell';
import { useTestViewState } from '@/hooks/tests-view';
import { forwardRef, useEffect } from 'react';
import baseClasses from './Sidebar.module.css';
import classes from './SidebarExpanded.module.css';
import cx from 'clsx';

type SidebarExpandedProps = {
  offset?: number;
};

const SidebarExpanded = forwardRef<HTMLDivElement, SidebarExpandedProps>(
  ({ offset }, ref) => {
    const { isTestViewOpen, isRecorderOpen } = useTestViewState();
    const isExpanded = appShellState.isSidebarExpanded && isTestViewOpen;

    useEffect(() => {
      window.document.body.style.overflow = isExpanded ? 'hidden' : 'auto';
      window.document.getElementById('mj-main-container')!.style.opacity =
        isExpanded ? '0.4' : '1';
    }, [isExpanded]);

    return (
      <AppShell.Navbar
        ref={ref}
        style={{
          left: offset,
        }}
        className={cx(classes.expandedSection, {
          [classes.expanded]: isExpanded,
          [classes.recorderOpened]: isRecorderOpen,
        })}
        p={0}
      >
        <ScrollArea
          mt="xs"
          styles={{
            root: {
              width: '100%',
            },
          }}
          classNames={{
            root: cx(baseClasses.scrollArea),
            scrollbar: baseClasses.scrollBar,
            thumb: baseClasses.scrollThumb,
            viewport: baseClasses.scrollViewport,
          }}
        >
          <div id="sidebar-expanded-portal" />
        </ScrollArea>
      </AppShell.Navbar>
    );
  },
);
SidebarExpanded.displayName = 'SidebarExpanded';

export default observer(SidebarExpanded);
