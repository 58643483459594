import { Avatar, AvatarProps } from '@mantine/core';
import chrome from '@browser-logos/chrome/chrome.svg';
import firefox from '@browser-logos/firefox/firefox.svg';
import safari from '@browser-logos/safari/safari.svg';
import edge from '@browser-logos/edge/edge.svg';

const supportedBrowsers = {
  chrome,
  firefox,
  safari,
  edge,
};

export type BrowserLogoProps = AvatarProps & {
  browser: keyof typeof supportedBrowsers;
};

export default function BrowserLogo({
  browser,
  ...othersProps
}: BrowserLogoProps) {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <Avatar
      src={supportedBrowsers[browser]}
      alt={browser}
      data-testid="browser-logo"
      {...othersProps}
    />
  );
}
