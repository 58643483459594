import { gql } from '@/__generated__';
import { colorsIcons } from '@/components/tests/list/PastTestResult';
import {
  ActionIcon,
  Button,
  Group,
  Loader,
  NavLink,
  Popover,
  Stack,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { ExecutionStatus } from '@mockingjay-io/shared-dependencies/src/types/entities/test-execution';
import { PiQueue } from '@react-icons/all-files/pi/PiQueue';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import classes from './ExecutionsPopup.module.css';
import { supportedBrowsersMap } from '@/data/browsers';
import { Browser } from '@mockingjay-io/shared-dependencies/src/types/entities/device';
import BrowserLogo from '@/components/bricks/BrowserLogo/BrowserLogo';

const executionsPopupQuery = gql(/* GraphQL */ `
  query ExecutionsPopup {
    testExecutions(orderBy: { createdAt: DESC }, limit: 5) {
      __typename
      id
      serialId
      status
      createdAt
      startedAt
      browser
      completedAt
      test {
        __typename
        id
        name
      }
    }
  }
`);

type ExecutionsPopupProps = {};

function ExecutionsPopup({}: ExecutionsPopupProps) {
  const [opened, setOpened] = useState(false);
  const [{ data, fetching }, refetchTestExecutions] = useQuery({
    query: executionsPopupQuery,
  });

  useEffect(() => {
    if (opened) {
      refetchTestExecutions();
      const intervalHandle = setInterval(() => refetchTestExecutions(), 5000);
      return () => clearInterval(intervalHandle);
    }
  }, [opened, refetchTestExecutions]);

  return (
    <Popover opened={opened} onChange={setOpened} withArrow>
      <Popover.Target>
        <Tooltip position="left" label="Executions" withArrow>
          <ActionIcon
            variant="light"
            color="cyan"
            radius="xl"
            title="Executions"
            onClick={() => setOpened((o) => !o)}
          >
            <PiQueue />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdown}>
        <Stack gap={0}>
          {data?.testExecutions.map((execution) => {
            const color =
              colorsIcons[execution.status as ExecutionStatus].color;
            const icon = [
              ExecutionStatus.Processing,
              ExecutionStatus.Pending,
            ].includes(execution.status as any) ? (
              <ThemeIcon
                className={classes.icon}
                variant="light"
                radius="md"
                size="lg"
                color="gray"
              >
                <Loader color={color} size="xs" mr="xs" ml="xs" />
              </ThemeIcon>
            ) : (
              <ThemeIcon
                className={classes.icon}
                variant="light"
                radius="md"
                size="lg"
                color={color}
              >
                {colorsIcons[execution.status as ExecutionStatus].icon}
              </ThemeIcon>
            );

            let description = '';
            if (execution.completedAt) {
              description += `Completed ${dayjs(
                execution.completedAt,
              ).fromNow()}`;
            } else if (execution.startedAt) {
              description += `Started ${dayjs(execution.startedAt).fromNow()}`;
            } else if (execution.createdAt) {
              description += `Queued ${dayjs(execution.createdAt).fromNow()}`;
            }

            return (
              <NavLink
                className={classes.navLink}
                label={execution.test.name}
                component={Link}
                key={execution.id}
                href={`/flows/tests/${execution.test.id}/executions/${execution.id}`}
                leftSection={icon}
                onClickCapture={() => setOpened(false)}
                description={
                  <Group gap={6}>
                    <BrowserLogo
                      mt={-2}
                      size="xs"
                      browser={
                        supportedBrowsersMap[execution.browser as Browser].icon
                      }
                    />
                    {description}
                  </Group>
                }
              />
            );
          })}
          <Group justify="center" m="sm">
            <Button
              component={Link}
              href="/executions"
              size="compact-xs"
              variant="default"
              color="gray"
              loading={fetching}
              onClickCapture={() => setOpened(false)}
            >
              See all executions
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

export default ExecutionsPopup;
