import { Text, Stack, Breadcrumbs, Anchor } from '@mantine/core';
import logo from '@/static/images/logo.svg';

function Footer() {
  return (
    <Stack
      gap={0}
      pt={40}
      pb="md"
      align="center"
      style={{
        width: '100%',
        position: 'relative',
        bottom: 0,
      }}
    >
      <img
        alt="Mockingjay logo"
        src={logo}
        width={25}
        style={{ pointerEvents: 'none' }}
      />
      <Breadcrumbs mb={8} mt={12} separator="·" separatorMargin={6}>
        <Anchor
          size="sm"
          c="dimmed"
          href="https://mockingjay.io/terms/"
          target="_blank"
        >
          Terms
        </Anchor>
        <Anchor
          size="sm"
          c="dimmed"
          href="https://mockingjay.io/privacy-policy"
          target="_blank"
        >
          Privacy
        </Anchor>
        <Anchor
          size="sm"
          c="dimmed"
          href="https://status.mockingjay.io"
          target="_blank"
        >
          Status
        </Anchor>
      </Breadcrumbs>
      <Text size="sm" c="dimmed" style={{ pointerEvents: 'none' }}>
        &copy; {new Date().getFullYear()} Mockingjay Pte Ltd.
      </Text>
    </Stack>
  );
}

export default Footer;
