import config from '@/config';
import { isBrowser } from '@/utils/ssr';
import { User } from '@/store/auth';
import logger from '@/utils/logger';
import { Router } from 'next/router';
import type { Mixpanel } from 'mixpanel-browser';

const debug = config.ENV === 'development';

/**
 * A decorator to ensure the method calls are queued if the mixpanel library is not ready yet.
 *
 * @param target
 * @param propertyKey
 * @param descriptor
 */
function queued(
  target: TrackerStore,
  propertyKey: keyof TrackerStore,
  descriptor: PropertyDescriptor,
) {
  const originalMethod: any = descriptor.value;
  descriptor.value = function (this: TrackerStore, ...argumentsArray: any[]) {
    if (!this.mixpanel) {
      if (debug) {
        logger.debug(
          { propertyKey, argumentsArray },
          '[tracker] adding to backlog for replay',
        );
      }
      this.backlog.push([propertyKey, argumentsArray]);
      return;
    }
    return originalMethod.apply(this, argumentsArray);
  };
}

class TrackerStore {
  mixpanel!: Mixpanel;
  backlog: [string, any[]][] = [];

  /**
   * Identify a user into the tracker.
   *
   * @param user
   */
  @queued
  identify(user: User) {
    const { id, name, email, lastLoginAt, createdAt, updatedAt } = user;
    this.mixpanel.identify(id);
    this.mixpanel.people.set({
      $email: email,
      $avatar: user.pictureUrl,
      $name: name,
      $created: createdAt,
      lastLoginAt,
      updatedAt,
    });
  }

  /**
   * Reset user identity.
   */
  @queued
  reset() {
    this.mixpanel.reset();
  }

  /**
   * Track an event.
   *
   * @param event
   * @param properties
   */
  @queued
  track(event: string, properties?: Record<string, any>) {
    this.mixpanel.track(event, properties);
  }

  /**
   * Track an event.
   *
   * @param path
   * @param properties
   */
  @queued
  trackPage(path: string, properties?: Record<string, any>) {
    const titleParts = document.title.split(' | ');
    if (!titleParts || titleParts.length <= 1) {
      return;
    }
    this.mixpanel.track(`${titleParts.slice(0, -1).join(' | ')} Page`, {
      ...properties,
      page: true,
      path,
    });
  }

  /**
   * Create the tracker.
   */
  constructor() {
    if (!isBrowser() || !config.MIXPANEL_PROJECT_TOKEN) {
      return;
    }

    // Lazy load and initialize the mixpanel library
    import('mixpanel-browser')
      .then((mixpanel) => {
        mixpanel.init(config.MIXPANEL_PROJECT_TOKEN, {
          debug: false,
          cross_subdomain_cookie: false,
          persistence: 'localStorage',
          secure_cookie: !debug,
          api_host: 'https://api-eu.mixpanel.com',
          ignore_dnt: true,
          loaded: (mp) => {
            mp.register({
              environment: config.ENV,
            });
            this.mixpanel = mp;
            if (debug) {
              logger.debug(mp, '[tracker] mixpanel loaded');
            }

            // Since mixpanel is now ready, replay all the events from the backlog.
            let backlogItem;
            while (
              typeof (backlogItem = this.backlog.shift()) !== 'undefined'
            ) {
              const [propertyKey, argumentsArray] = backlogItem;
              if (debug) {
                logger.debug(
                  { propertyKey, argumentsArray },
                  '[tracker] replaying backlog',
                );
              }
              (this as any)[propertyKey](...argumentsArray);
            }
          },
        });
      })
      .catch((e) =>
        logger.error(e, 'unable to load mixpanel. user analytics is disabled.'),
      );

    Router.events.on('routeChangeComplete', (path) => {
      this.trackPage(path);
    });
  }
}

const tracker = new TrackerStore();
export default tracker;
