import { PiWebhooksLogoLight } from '@react-icons/all-files/pi/PiWebhooksLogoLight';
import { RiFlowChart } from '@react-icons/all-files/ri/RiFlowChart';
import { PiShieldStar } from '@react-icons/all-files/pi/PiShieldStar';
import { PiHeartbeat } from '@react-icons/all-files/pi/PiHeartbeat';
import { PiWaves } from '@react-icons/all-files/pi/PiWaves';
import { PiFunction } from '@react-icons/all-files/pi/PiFunction';
import { IconType } from '@react-icons/all-files';
import authState from '@/store/auth';
import { PiUsersFour } from '@react-icons/all-files/pi/PiUsersFour';
import { PiArrowBendUpLeft } from '@react-icons/all-files/pi/PiArrowBendUpLeft';
import { ActiveLinkProps } from '@/components/bricks/ActiveLink/ActiveLink';

type NavigationItem = ActiveLinkProps & {
  href: string;
  label: string;
  Icon: IconType;
  teaser?: boolean;
  enabled?: () => boolean;
};

export const topLevelNavigation: NavigationItem[] = [
  {
    href: '/health',
    label: 'Health',
    Icon: PiHeartbeat,
  },
  {
    href: '/flows',
    label: 'Flows',
    Icon: PiWaves,
  },
  {
    href: '/suites',
    label: 'Suites',
    Icon: RiFlowChart,
  },
  {
    href: '/apis',
    label: 'API',
    Icon: PiWebhooksLogoLight,
  },
  {
    href: '/security',
    label: 'Security',
    Icon: PiShieldStar,
    enabled: () => authState.features.securityTesting,
  },
  {
    href: '/functionality',
    label: 'Functionality',
    Icon: PiFunction,
  },
];

export const topLevelGlobalNavigation: NavigationItem[] = [
  {
    href: '/',
    label: 'Back',
    Icon: PiArrowBendUpLeft,
    activeClassName: '',
  },
  {
    href: '/global/teams',
    label: 'Teams',
    Icon: PiUsersFour,
  },
];
