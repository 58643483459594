import {
  CSSVariablesResolver,
  ColorSchemeScript,
  MantineProvider,
} from '@mantine/core';
import { PropsWithChildren, memo } from 'react';
import config from './config';
import { HEADER_HEIGHT } from './components/navigation/Header/Header';

export const MIN_WIDTH = 1366;

const resolver: CSSVariablesResolver = (theme) => {
  return {
    variables: {
      '--mj-header-height': `${HEADER_HEIGHT}px`,
    },
    light: {
      '--mj-color-body-background': theme.colors.gray[0],
    },
    dark: {
      '--mj-color-body-background': theme.colors.dark[9],
    },
  };
};

function ThemeProvider({ children }: PropsWithChildren) {
  return (
    <MantineProvider
      cssVariablesResolver={resolver}
      defaultColorScheme={config.DEFAULT_COLOR_SCHEME}
    >
      <ColorSchemeScript defaultColorScheme={config.DEFAULT_COLOR_SCHEME} />
      {children}
    </MantineProvider>
  );
}

export default memo(ThemeProvider);
