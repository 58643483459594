export type ListenerCallback<DataType = any> = (data: DataType) => void;

export class Listenable<EventsType extends string, DataType = any> {
  private listeners: Record<EventsType, Array<ListenerCallback<DataType>>>;

  constructor() {
    this.listeners = {} as Record<
      EventsType,
      Array<ListenerCallback<DataType>>
    >;
  }

  on(event: EventsType, callback: ListenerCallback<DataType>) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  emit(event: EventsType, data: DataType) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((callback) => callback(data));
    }
  }

  off(event: EventsType, callback: ListenerCallback<DataType>) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (cb) => cb !== callback,
      );
    }
  }

  once(event: EventsType, callback: ListenerCallback<DataType>) {
    const onceCallback: ListenerCallback<DataType> = (...args) => {
      callback(...args);
      this.off(event, onceCallback);
    };
    this.on(event, onceCallback);
  }
}
