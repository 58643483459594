import { useRouter } from 'next/router';
import { isValidUUIDv4 } from '@mockingjay-io/shared-dependencies/src/utils/validations';
import { TestStepProps } from '@/components/tests/view/TestStep/TestStep';
import { useMemo } from 'react';

const TEST_EXECUTION_VIEWS = new Set([
  '/flows/tests/[testId]/executions/[executionId]',
  '/flows/tests/[testId]/executions/[executionId]/api',
  '/apis/tests/[testId]/executions/[executionId]',
  '/apis/tests/[testId]/executions/[executionId]',
  '/flows/tests/[testId]/executions/[executionId]/security',
]);

const TEST_RECORDER_VIEWS = new Set([
  '/flows/tests/[testId]/recordings/[recordingId]',
  '/flows/blocks/[blockId]/recordings/[recordingId]',
]);

const TEST_VIEWS = new Set([
  '/flows/tests/[testId]',
  '/flows/tests/[testId]/api',
  '/flows/blocks/[blockId]',
  '/apis/tests/[testId]',
  ...TEST_RECORDER_VIEWS,
  ...TEST_EXECUTION_VIEWS,
]);

/**
 * Hook to return the state of the recorder.
 */
export function useTestViewState(): {
  isTestViewOpen: boolean;
  isApiTestViewOpen: boolean;
  isTestExecutionViewOpen: boolean;
  isRecorderOpen: boolean;
} {
  const { route, query } = useRouter();
  const { testId, blockId } = query as Record<string, string>;
  return {
    isTestExecutionViewOpen: TEST_EXECUTION_VIEWS.has(route),
    isTestViewOpen: TEST_VIEWS.has(route) && isValidUUIDv4(testId || blockId),
    isRecorderOpen: TEST_RECORDER_VIEWS.has(route),
    isApiTestViewOpen: route.startsWith('/apis/tests/'),
  };
}

export type UseTestStepDividerCallbacksOptions = Pick<
  TestStepProps,
  | 'stepIdx'
  | 'subStepIdx'
  | 'onInsertBlock'
  | 'onAddExplicitWait'
  | 'onInsertHttpRequest'
  | 'onInsertAiStep'
  | 'onInsertStep'
  | 'onInsertSteps'
  | 'onReRecord'
  | 'onPaste'
>;

export function useTestStepDividerCallbacks({
  stepIdx,
  subStepIdx,
  onAddExplicitWait,
  onInsertHttpRequest,
  onInsertAiStep,
  onInsertBlock,
  onInsertStep,
  onInsertSteps,
  onReRecord,
  onPaste,
}: UseTestStepDividerCallbacksOptions) {
  const onInsertBlockCallbacks = useMemo(() => {
    if (!onInsertBlock) {
      return {};
    }
    return {
      top: () => onInsertBlock(stepIdx),
      bottom: () => onInsertBlock(stepIdx + 1),
    };
  }, [stepIdx, onInsertBlock]);

  const onInsertStepCallbacks = useMemo(() => {
    if (!onInsertStep) {
      return {};
    }
    return {
      top: () => onInsertStep?.(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onInsertStep(stepIdx, subStepIdx + 1)
          : onInsertStep(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onInsertStep]);

  const onInsertStepsCallbacks = useMemo(() => {
    if (!onInsertSteps) {
      return {};
    }
    return {
      top: () => onInsertSteps(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onInsertSteps(stepIdx, subStepIdx + 1)
          : onInsertSteps(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onInsertSteps]);

  const onAddExplicitWaitCallbacks = useMemo(() => {
    if (!onAddExplicitWait) {
      return {};
    }
    return {
      top: () => onAddExplicitWait(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onAddExplicitWait(stepIdx, subStepIdx + 1)
          : onAddExplicitWait(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onAddExplicitWait]);

  const onReRecordCallbacks = useMemo(() => {
    if (!onReRecord) {
      return {};
    }
    return {
      top: () => onReRecord(stepIdx),
      bottom: () => onReRecord(stepIdx + 1),
    };
  }, [stepIdx, onReRecord]);

  const onPasteCallbacks = useMemo(() => {
    if (!onPaste) {
      return {};
    }
    return {
      top: () => onPaste(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onPaste(stepIdx, subStepIdx + 1)
          : onPaste(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onPaste]);

  const onInsertHttpRequestCallbacks = useMemo(() => {
    if (!onInsertHttpRequest) {
      return {};
    }
    return {
      top: () => onInsertHttpRequest(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onInsertHttpRequest(stepIdx, subStepIdx + 1)
          : onInsertHttpRequest(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onInsertHttpRequest]);

  const onInsertAiStepCallbacks = useMemo(() => {
    if (!onInsertAiStep) {
      return {};
    }
    return {
      top: () => onInsertAiStep(stepIdx, subStepIdx),
      bottom: () =>
        typeof subStepIdx !== 'undefined'
          ? onInsertAiStep(stepIdx, subStepIdx + 1)
          : onInsertAiStep(stepIdx + 1),
    };
  }, [stepIdx, subStepIdx, onInsertAiStep]);

  return {
    onInsertBlock: onInsertBlockCallbacks,
    onInsertStep: onInsertStepCallbacks,
    onInsertSteps: onInsertStepsCallbacks,
    onAddExplicitWait: onAddExplicitWaitCallbacks,
    onReRecord: onReRecordCallbacks,
    onPaste: onPasteCallbacks,
    onInsertHttpRequest: onInsertHttpRequestCallbacks,
    onInsertAiStep: onInsertAiStepCallbacks,
  };
}
