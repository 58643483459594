import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';

export type ActiveLinkProps = PropsWithChildren<LinkProps> & {
  activeClassName?: string;
};

function ActiveLink({
  children,
  activeClassName = 'active',
  ...props
}: ActiveLinkProps) {
  const { asPath, isReady } = useRouter();

  const child = Children.only(children) as ReactElement;
  const childClassName = child?.props?.className || '';
  const [className, setClassName] = useState(childClassName);

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (props.as || props.href) as any,
        location.href,
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;

      const newClassName =
        linkPathname === activePathname ||
        activePathname.startsWith(linkPathname)
          ? `${childClassName} ${activeClassName}`.trim()
          : childClassName;

      if (newClassName !== className) {
        setClassName(newClassName);
      }
    }
  }, [
    asPath,
    isReady,
    props.as,
    props.href,
    childClassName,
    activeClassName,
    setClassName,
    className,
  ]);

  return (
    <Link passHref={false} {...props} legacyBehavior>
      {React.cloneElement(child, {
        className: className || null,
        href: props.href,
        'data-testid': (props as any)['data-testid'] || 'link',
      })}
    </Link>
  );
}

export default ActiveLink;
