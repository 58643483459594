import Script from 'next/script';
import authState, { User } from '@/store/auth';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import config from '@/config';

const chatVisibilityStack: boolean[] = [];

export function toggleChatWidgetVisibility(visible: boolean): void {
  const { fcWidget } = window as any;
  if (!fcWidget || !fcWidget.isInitialized()) {
    chatVisibilityStack.push(visible);
    return;
  }
  if (visible) {
    fcWidget.show();
  } else {
    fcWidget.hide();
  }
}

function setupChatWidget(isAuthenticated: boolean, user: User | null): void {
  const { fcWidget } = window as any;
  if (!fcWidget) {
    return;
  }
  const { FRESH_CHAT_TOKEN, FRESH_CHAT_HOST } = config;
  if (!FRESH_CHAT_TOKEN || !FRESH_CHAT_HOST) {
    return;
  }
  if (isAuthenticated && user) {
    fcWidget.init({
      token: FRESH_CHAT_TOKEN,
      host: FRESH_CHAT_HOST,
      externalId: user.id,
      firstName: user.name,
      lastName: '',
      email: user.email,
    });
    setTimeout(() => {
      while (chatVisibilityStack.length > 0) {
        const stackValue = chatVisibilityStack.pop();
        if (typeof stackValue === 'boolean') {
          stackValue ? fcWidget.show() : fcWidget.hide();
        }
      }
    }, 1);
  } else {
    if (fcWidget.isInitialized()) {
      fcWidget.destroy();
    }
  }
}

function ChatWidget() {
  useEffect(() => {
    return reaction(
      () => [authState.isAuthenticated, authState.user] as [boolean, User],
      ([isAuthenticated, user]) => {
        setupChatWidget(isAuthenticated, user);
      },
    );
  }, []);

  return (
    <Script
      src="https://wchat.freshchat.com/js/widget.js"
      onLoad={() => setupChatWidget(authState.isAuthenticated, authState.user)}
    />
  );
}

export default ChatWidget;
