import type { PropsWithChildren, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import authState from '@/store/auth';
import logger from '@/utils/logger';
import { LoadingOverlay } from '@mantine/core';
import config from '@/config';

const authGuardConfig = config.authGuard;

function AuthGuard({ children }: PropsWithChildren) {
  const router = useRouter();
  const { isAuthenticated, loading, user } = authState;
  const [isVisible, setIsVisible] = useState(false);
  const validateAuth = useCallback(async () => {
    setIsVisible(false);
    if (loading) {
      return;
    }
    let isRedirect = false;
    if (isAuthenticated) {
      if (authGuardConfig.publicPaths?.includes(router.route)) {
        isRedirect = true;
        const returnUrl = window.sessionStorage.getItem('return_url');
        if (returnUrl) {
          await router.replace(returnUrl);
          window.sessionStorage.removeItem('return_url');
        } else {
          await router.replace(authGuardConfig.authorizedLandingPath);
        }
      } else if (
        authGuardConfig.globalRolePrefixes.some((prefix) =>
          router.route.startsWith(prefix),
        ) &&
        !user?.globalRole
      ) {
        isRedirect = true;
        await router.replace(authGuardConfig.authorizedLandingPath);
      }
    } else {
      if (
        !authGuardConfig.publicPaths?.includes(router.route) &&
        !authGuardConfig.anyPaths?.includes(router.route)
      ) {
        isRedirect = true;
        await router.replace({
          pathname: authGuardConfig.loginPath,
          query: {
            return_url: window.location.pathname + window.location.search,
          },
        });
      }
    }

    if (!isRedirect) {
      setIsVisible(true);
    }
  }, [loading, isAuthenticated, router, user?.globalRole]);

  useEffect(() => {
    validateAuth().catch((e) => logger.error(e));
  }, [validateAuth, router.isReady]);

  return (isVisible ? children : <LoadingOverlay visible />) as ReactElement;
}

export default observer(AuthGuard);
