import { action, makeObservable, observable } from 'mobx';

class AppShellStore {
  @observable isSidebarExpanded = false;
  @observable isSidebarCollapsed = false;
  @observable errorMode = false;
  @observable footerVisible = true;

  @action
  toggleSidebar(value?: boolean) {
    this.isSidebarCollapsed = value ?? !this.isSidebarCollapsed;
  }

  @action
  toggleErrorMode(value?: boolean) {
    this.errorMode = value ?? !this.errorMode;
  }

  @action
  toggleFooterVisible(value?: boolean) {
    this.footerVisible = value ?? !this.footerVisible;
  }

  constructor() {
    makeObservable(this);
  }
}

const appShellState = new AppShellStore();
export default appShellState;
