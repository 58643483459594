import React, { ReactNode, useCallback, useState } from 'react';
import {
  ActionIcon,
  ActionIconProps,
  Group,
  Loader,
  MantineColor,
  Popover,
  Text,
} from '@mantine/core';
import BrowserLogo from '../../bricks/BrowserLogo/BrowserLogo';
import { FiCheck } from '@react-icons/all-files/fi/FiCheck';
import { FiX } from '@react-icons/all-files/fi/FiX';
import dayjs from 'dayjs';
import { ExecutionStatus } from '@mockingjay-io/shared-dependencies/src/types/entities/test-execution';
import { FiRefreshCw } from '@react-icons/all-files/fi/FiRefreshCw';
import { FiStopCircle } from '@react-icons/all-files/fi/FiStopCircle';
import { supportedBrowsersMap } from '@/data/browsers';
import { useRouter } from 'next/router';
import { FragmentType, getFragmentData, gql } from '@/__generated__';
import { PastTestResultPartsFragmentDoc } from '@/__generated__/graphql';

const PastTestResultFragment = gql(/* GraphQL */ `
  fragment PastTestResultParts on TestExecutions {
    __typename
    id
    testId
    status
    updatedAt
    browser
  }
`);

type PastTestResultProps = ActionIconProps & {
  testExecution: FragmentType<typeof PastTestResultPartsFragmentDoc>;
};

export const colorsIcons: Record<
  ExecutionStatus,
  { color: MantineColor; icon: ReactNode }
> = {
  [ExecutionStatus.Cancelled]: {
    color: 'gray',
    icon: <FiStopCircle />,
  },
  [ExecutionStatus.Success]: {
    color: 'green',
    icon: <FiCheck />,
  },
  [ExecutionStatus.Failed]: {
    color: 'red',
    icon: <FiX />,
  },
  [ExecutionStatus.Errored]: {
    color: 'red',
    icon: <FiX />,
  },
  [ExecutionStatus.Processing]: {
    color: 'cyan',
    icon: <FiRefreshCw />,
  },
  [ExecutionStatus.Pending]: {
    color: 'cyan',
    icon: <FiRefreshCw />,
  },
};

export default function PastTestResult({
  testExecution: testExecutionRef,
  ...actionIconProps
}: PastTestResultProps) {
  const data = getFragmentData(
    PastTestResultPartsFragmentDoc,
    testExecutionRef,
  );

  const router = useRouter();
  const [opened, setOpened] = useState(false);
  const { status, updatedAt, browser } = data;

  const gotoExecution = useCallback(() => {
    return router.push(`/flows/tests/${data.testId}/executions/${data.id}`);
  }, [router, data.id, data.testId]);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="bottom"
      withArrow
      trapFocus={false}
      closeOnEscape={false}
      styles={{ dropdown: { pointerEvents: 'none' } }}
    >
      <Popover.Target>
        {[ExecutionStatus.Pending, ExecutionStatus.Processing].includes(
          status as any,
        ) ? (
          <div
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
            style={{ marginBottom: -5 }}
          >
            <Loader size="xs" />
          </div>
        ) : (
          <ActionIcon
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
            onClick={gotoExecution}
            size="xs"
            variant="filled"
            radius="xl"
            color={(colorsIcons as any)[status].color}
            {...actionIconProps}
          >
            {React.cloneElement((colorsIcons as any)[status].icon as any, {
              size: 14,
            })}
          </ActionIcon>
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <Group gap={6}>
          <BrowserLogo
            browser={(supportedBrowsersMap as any)[browser].icon}
            size="xs"
          />
          <Text size="sm">
            <span>{dayjs(updatedAt).format('ll')}</span>
          </Text>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
