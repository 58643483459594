import pino from 'pino';

const logger = pino({
  browser: {
    asObject: false,
  },
  serializers: {
    err: pino.stdSerializers.err,
    error: pino.stdSerializers.err,
    e: pino.stdSerializers.err,
  },
});

export default logger;
