import Script from 'next/script';
import config from '@/config';
import { useEffect } from 'react';
import { autorun } from 'mobx';
import authState from '@/store/auth';

function ClarityAnalytics() {
  useEffect(() => {
    if (!config.CLARITY_PROJECT_ID) {
      return;
    }
    return autorun(() => {
      if (!authState.isAuthenticated || !authState.user) {
        return;
      }
      (window as any).clarity(
        'identify',
        authState.user.id,
        undefined,
        undefined,
        authState.user.name,
      );
      if (authState.selectedTeam) {
        (window as any).clarity('set', 'team-id', authState.selectedTeam.id);
        (window as any).clarity(
          'set',
          'team-name',
          authState.selectedTeam.name,
        );
      }
    });
  }, []);

  if (!config.CLARITY_PROJECT_ID) {
    return null;
  }

  return (
    <Script
      id="microsoft-clarity"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${config.CLARITY_PROJECT_ID}");
  `,
      }}
    />
  );
}

export default ClarityAnalytics;
