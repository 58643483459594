import {
  Avatar,
  Group,
  Menu,
  ScrollArea,
  Text,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { observer } from 'mobx-react-lite';
import authState from '@/store/auth';
import { MdLightMode } from '@react-icons/all-files/md/MdLightMode';
import { MdModeNight } from '@react-icons/all-files/md/MdModeNight';
import { IoMdLogOut } from '@react-icons/all-files/io/IoMdLogOut';
import { FiSettings } from '@react-icons/all-files/fi/FiSettings';
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { FiCircle } from '@react-icons/all-files/fi/FiCircle';
import { useCallback } from 'react';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import Link from 'next/link';
import { useFinalColorScheme } from '@/hooks/mantine';
import classes from './UserSettings.module.css';
import { PiGlobe } from '@react-icons/all-files/pi/PiGlobe';
import { PiArrowBendUpLeft } from '@react-icons/all-files/pi/PiArrowBendUpLeft';

function UserSettings() {
  const theme = useMantineTheme();
  const { toggleColorScheme } = useMantineColorScheme();
  const colorScheme = useFinalColorScheme();
  const delayedToggleColorScheme = () => {
    setTimeout(() => toggleColorScheme(), 100);
  };

  const pictureUrl = authState.user?.pictureUrl || '';

  const onTeamSelection = useCallback((teamId: string) => {
    authState.switchTeam(teamId);
  }, []);

  return (
    <Menu
      shadow="md"
      position="bottom-end"
      withArrow
      classNames={{ dropdown: classes.menuDropdown }}
    >
      <Menu.Target>
        <UnstyledButton>
          <Avatar
            src={pictureUrl}
            imageProps={{ referrerPolicy: 'no-referrer' }}
          />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item className={classes.unselectable}>
          <Group>
            <Avatar
              radius="xl"
              src={pictureUrl}
              imageProps={{ referrerPolicy: 'no-referrer' }}
            />
            <div>
              <Text className={classes.userName}>
                {authState.user?.name || ''}
              </Text>
              <Text size="xs" c="dimmed">
                {authState.user?.email || ''}
              </Text>
            </div>
          </Group>
        </Menu.Item>
        <Menu.Divider />
        {authState.assumeTeamRole && (
          <>
            <Menu.Label>
              Assumed {authState.assumeTeamRole.toLowerCase()} role on
            </Menu.Label>
            <Menu.Item
              className={classes.unselectable}
              leftSection={
                <FiCheckCircle size={14} color={theme.colors.green[6]} />
              }
            >
              {authState.selectedTeam?.name || ''}
            </Menu.Item>
            <Menu.Item
              onClick={() => onTeamSelection(authState?.teams?.[0]?.id || '')}
              leftSection={<PiArrowBendUpLeft />}
            >
              Exit assumed role
            </Menu.Item>
          </>
        )}

        <Menu.Label>Your Teams</Menu.Label>
        <ScrollArea h={Math.min(authState?.teams?.length || 0, 10) * 35.02}>
          {authState?.teams?.map((team) => {
            const selected = team.id === authState.selectedTeam?.id;
            return (
              <Menu.Item
                key={team.id}
                h={35.02}
                className={selected ? classes.unselectable : ''}
                onClick={() => onTeamSelection(team.id)}
                leftSection={
                  selected ? (
                    <FiCheckCircle size={14} color={theme.colors.green[6]} />
                  ) : (
                    <FiCircle size={14} style={{ opacity: 0.6 }} />
                  )
                }
              >
                {team.name}
              </Menu.Item>
            );
          })}
        </ScrollArea>

        <Menu.Label>Settings</Menu.Label>
        {colorScheme === 'dark' ? (
          <Menu.Item
            leftSection={<MdLightMode />}
            onClick={delayedToggleColorScheme}
          >
            Light Mode
          </Menu.Item>
        ) : (
          <Menu.Item
            leftSection={<MdModeNight />}
            onClick={delayedToggleColorScheme}
          >
            Dark Mode
          </Menu.Item>
        )}
        <Menu.Item
          component={Link}
          href="/mailbox"
          leftSection={<FiMail size={14} />}
        >
          Mailbox
        </Menu.Item>
        <Menu.Item
          component={Link}
          href="/settings"
          leftSection={<FiSettings size={14} />}
        >
          Settings
        </Menu.Item>
        {authState.user?.globalRole && (
          <Menu.Item
            component={Link}
            href="/global"
            leftSection={<PiGlobe size={14} />}
          >
            Global Home
          </Menu.Item>
        )}
        <Menu.Item
          leftSection={<IoMdLogOut />}
          onClick={() => authState.signOut()}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default observer(UserSettings);
