import { useRef, type PropsWithChildren, useCallback } from 'react';
import { AppShell as MantineAppShell } from '@mantine/core';
import Sidebar from '../Sidebar/Sidebar';
import { useTestViewState } from '@/hooks/tests-view';
import Header from '@/components/navigation/Header/Header';
import { useSidebarWidth } from '@/hooks/sidebar';
import Footer from '../Footer/Footer';
import appShellState from '@/store/app-shell';
import { observer } from 'mobx-react-lite';
import classes from './AppShell.module.css';
import { MIN_WIDTH } from '@/theme';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import ErrorHandler from '@/components/bricks/ErrorBoundary/ErrorHandler';

function AppShell({ children }: PropsWithChildren<any>) {
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const appShellRef = useRef<HTMLDivElement>(null);
  const { isTestViewOpen, isRecorderOpen, isTestExecutionViewOpen } =
    useTestViewState();
  const width = useSidebarWidth(appShellState.errorMode);

  const onDynamicSidebarWidthChange = useCallback(
    (width: number) => {
      if (mainContainerRef.current) {
        mainContainerRef.current.style.paddingLeft = `${width}px`;
      }
    },
    [mainContainerRef],
  );

  return (
    <MantineAppShell
      header={{
        height: 'var(--mj-header-height)',
        collapsed: !appShellState.errorMode && isRecorderOpen,
      }}
      navbar={{ width: width, breakpoint: 'sm' }}
      miw={MIN_WIDTH}
      style={{
        '--mantine-default-sidebar-width': width,
      }}
      ref={appShellRef}
    >
      {!isRecorderOpen && <Header isMinimized={false} />}
      {!appShellState.isSidebarCollapsed && (
        <Sidebar
          navListHidden={!appShellState.errorMode && isTestViewOpen}
          width={width}
          errorMode={appShellState.errorMode}
          onDynamicWidthChange={onDynamicSidebarWidthChange}
        />
      )}
      <MantineAppShell.Main
        id="mj-main-container"
        ref={mainContainerRef}
        className={classes.container}
      >
        <ErrorBoundary errorComponent={ErrorHandler}>{children}</ErrorBoundary>
        {appShellState.footerVisible &&
          ((!appShellState.errorMode && isTestExecutionViewOpen) ||
            ((!isRecorderOpen || appShellState.errorMode) && <Footer />))}
      </MantineAppShell.Main>
    </MantineAppShell>
  );
}

export default observer(AppShell);
