import config from '@/config';
import { useComputedColorScheme } from '@mantine/core';

/**
 * Use the final color scheme.
 *
 * @returns
 */
export function useFinalColorScheme() {
  return useComputedColorScheme(config.DEFAULT_COLOR_SCHEME);
}
