import { useViewportSize } from '@mantine/hooks';
import { useTestViewState } from '@/hooks/tests-view';
import { useMemo } from 'react';
import { MIN_WIDTH } from '@/theme';

const breakpoints = [
  [2000, 0.2],
  [1800, 0.23],
  [1600, 0.26],
  [1408, 0.27],
  [MIN_WIDTH, 0.28],
];

/**
 * Get the widths to use for the sidebar.
 */
export function useSidebarWidth(errorMode?: boolean): number {
  const { width: viewportWidth } = useViewportSize();
  const { isRecorderOpen, isTestViewOpen, isApiTestViewOpen } =
    useTestViewState();

  return useMemo(() => {
    const adjustedViewportWidth = Math.max(viewportWidth, MIN_WIDTH);
    if (errorMode) {
      return 210;
    }
    if (!isRecorderOpen && !isTestViewOpen) {
      return 210;
    }

    if (isApiTestViewOpen) {
      return 0.4 * adjustedViewportWidth;
    }

    const [_, percentage] = breakpoints.find(
      ([breakpoint]) => adjustedViewportWidth >= breakpoint,
    ) ?? [0, 0.25];

    return percentage * adjustedViewportWidth;
  }, [
    errorMode,
    isApiTestViewOpen,
    isRecorderOpen,
    isTestViewOpen,
    viewportWidth,
  ]);
}
